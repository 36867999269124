import React, { } from 'react';
import './Services.css';
import Service from './Service';
import image1 from './img/pics/r.png';
import image2 from './img/pics/Rt.jpeg';
import transport from './img/pics/transport.png';
import portamaquinas from './img/pics/portamaquinas.png';
import reboque from './img/pics/reboque.png';
import aluguer from './img/pics/aluguer.JPG';
import Retroescavadeira from './img/pics/Retroescavadeira.JPG';
import agua from './img/pics/agua.JPG';
import pesados from './img/pics/pesados.png';

function Services() {
    

    return (
        <div className='services'>
            <h1>Nossos Serviços</h1>
            <div className='services__service'>
                <Service 
                    imgSrc={image1} 
                    title="Serviços Aduaneiros e Transitários" 
                    description="Como agentes aduaneiros, a TRANS-PC desenvolveu ao longo dos anos, especialidades e soluções específicas para otimizar a logística Aduaneira e Fiscal das suas Operações." 
                />
                <Service 
                    imgSrc={transport} 
                    title="Transporte de Mercadorias" 
                    description="Mais do que apenas entregar, a TRANS-PC busca oferecer excelente custo-benefício e eficiência, levando sempre em consideração a satisfação do cliente, trabalhamos assegurando sempre a mercadoria a ser transportada." 
                />
                <Service 
                    imgSrc={portamaquinas} 
                    title="Porta Máquinas" 
                    description="Se necessitar de transportar as suas máquinas, mudar os equipamentos de construção para uma outra obra e não tem como carregar e descarregar, ou se precisar de transportar e montar uma estrutura metálica, nós temos os equipamentos necessários." 
                />
                <Service 
                    imgSrc={reboque} 
                    title="Reboque" 
                    description="Quando o seu veículo avaria de forma inesperada é importante saber que pode contar com profissionais íntegros e qualificados. Nós fornecemos um serviço de reboque em Luanda, independentemente de quando e onde você precisar de nossa ajuda, oferecemos uma cobertura!" 
                />
                <Service 
                    imgSrc={Retroescavadeira} 
                    title="Máquina Retroescavadeira" 
                    description="Oferecem maior capacidade de escavação, abertura de valetas, aterramento e movimentação de materiais e podem ser usadas para muitas aplicações, incluindo mas não se limitando a: Construção Geral, Demolições e Escavações, Paisagismo, Quebra de Asfalto e Pavimentação." 
                />
                <Service 
                    imgSrc={aluguer} 
                    title="Rent a Car" 
                    description="Rent a Car, Procura um serviço de aluguer de carros rápido e fiável? Alugue um carro de qualidade em Angola para tornar a sua viagem num verdadeiro prazer." 
                />
                <Service 
                    imgSrc={agua} 
                    title="Venda de Água Potavel" 
                    description="A Ambiental BR está pronta para atendê-lo com rapidez e eficiência que só o nosso serviço de venda de água potável garante. O serviço de venda de água potável da Ambiental BR é feito em caminhões pipa com capacidade de 5 mil a 30 mil litros." 
                />
                <Service 
                    imgSrc={pesados} 
                    title="Oficina de Pesados" 
                    description="A transPC é uma rede de oficinas para pesados de dimensão internacional, que utiliza a sua vertente multimarca para oferecer aos seus clientes um conjunto de benefícos de qualidade elevada." 
                />
            </div>
        </div>
    )
}

export default Services;