import React from 'react';
import './ContactComponent.css';
import phone from './img/icons/phone.png';
import email from './img/icons/email.png';
import address from './img/icons/address.png';
import missao from './img/banner/contacto-missao.jpg';
import visao from './img/banner/contacto-visao.jpg';

const ContactComponent = () => {
    return (
        <div className="contact-component">
            <div className="image-container">
                <img src={missao} alt="Left" className="image"/>
                <img src={visao} alt="Right" className="image"/>
            </div>
            <div className="info-container">
                <div className="info-item">
                    <div className='icon'>
                        <img src={phone} alt="Ligue" />
                    </div>
                    <div className="title-container">
                        <h3>Ligue para Nós</h3>
                        <label>+244 923 910 216</label>
                        <label>+244 916 587 193</label>
                        <label>+244 923 356 553</label>
                    </div>
                </div>
                <div className="info-item info-item-middle">
                    <div className='icon'>
                        <img src={email} alt="Ligue" />
                    </div>
                    <div className="title-container">
                        <h3>Envie-nos um Email</h3>
                        <label>geral@transpc.ao</label>
                        <label>info@transpc.ao</label>
                    </div>
                </div>
                <div className="info-item">
                    <div className='icon'>
                        <img src={address} alt="Ligue" />
                    </div>
                    <div className="title-container">
                        <h3>Nosso Endereço</h3>
                        <label>President Business Center Largo 4 de fevereiro, 4ª Andar, Sala 420-422 Luanda Angola</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactComponent;