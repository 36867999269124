import React from 'react';
import './CompanyInfo.css';
import aboutus from './img/pics/aboutus.png'

const CompanyInfo = ({ title, paragraph1, paragraph2, paragraph3, paragraph4 }) => {
    return (
        <div className='companyinfo'>
            <h1 className='companyinfo_title'>Sobre Nós</h1>
            <div className='div'></div>
            <div className="company-info">
                <div className="image-container">
                    <img src={aboutus} alt="Company" />
                </div>
                <div className="text-container">
                    <h1>{title}</h1>
                    <p>{paragraph1}</p>
                    <p>{paragraph2}</p>
                    <p>{paragraph3}</p>
                    <p>{paragraph4}</p>
                </div>
                {/*<div className="image-container">
                    <img src={imgSrc} alt="Company" />
                 </div> */}
            </div>
        </div>
    );
};

export default CompanyInfo;