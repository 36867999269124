import React from 'react';
import './App.css';
import { Element } from 'react-scroll';
import Navbar from './Navbar';
import Banner from './Banner';
import Services from './Services';
import CompanyInfo from './CompanyInfo';
import ContactComponent from './ContactComponent';
import Footer from './Footer';
import './Mediaquery.css';

function App() {
    return (
        <div className='app'>
            <Element name="home">
                <Navbar />
                {/* Home content goes here */}
                <Banner />
            </Element>
            <Element name="services">
                {/* Logistics Services content goes here */}
                <Services />
            </Element>
            <Element name="warehouse">
                {/* Warehouse content goes here */}
            </Element>
            <Element name="transportations">
                {/* Transportations content goes here */}
            </Element>
            <Element name="about">
                {/* About Us content goes here */}
                <CompanyInfo 
                    title="TransPC, Transitários & Transporte" 
                    paragraph1="A TRANS-PC Lda, é uma empresa de capital Angolano, com mais de 15 anos de experiência, prestando serviços de transitários e aduaneiros com excelência, desde a gestão de processos para a retirada de mercadoria até a entrega da mesma ao cliente."
                    paragraph2="O nosso foco está na qualidade e máxima eficiência no resultado dos nossos serviços levando sempre em consideração a satisfação do cliente, trabalhamos assegurando sempre a mercadoria a ser transportada."  
                    paragraph3="A nossa equipa altamente experiente e profissional é proficiente na lei Angolana aduaneira e nos procedimentos para garantir um despacho ou desalfandegamento mais rápido."
                    paragraph4="Temos experiência em resolver problemas relacionados à alfândega para garantir que o cliente atenda aos apertados cronogramas de produção e vendas e um alto nível de precisão no preenchimento de documentos para alfândega e autoridades responsáveis." 
                />
            </Element>
            <Element name="contact">
                {/* Contact Us content goes here */}
                <ContactComponent />
                <Footer />
            </Element>
        </div>
    )
}

export default App;