import React, { useEffect, useState } from 'react';
import './Banner.css';
import image1 from './img/banner/blog3.jpg';
import image2 from './img/banner/portfolio-1.jpg';
import image3 from './img/banner/portfolio-2.png';
import truckGif from './img/icons/truck.gif';
import box from './img/icons/box.png';
import semi_trailer from './img/icons/semi-trailer-truck.png';
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { LiaBoxSolid } from "react-icons/lia";
import Modal from './Modal';

const Banner = () => {
    const [current, setCurrent] = useState(0); // Index of the current image
    const images = [image1, image2, image3]; // Array of images
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrent(prevImage => (prevImage + 1) % images.length);
        }, 7000);
    
        return () => clearInterval(intervalId);
    }, []);
  
    const previousImage = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    };
  
    const nextImage = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1);
    };

    const handleOpenModal = () =>{
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };
  
    return (
        <>
            <div className='banne'>
                <div className="banner">
                    <img src={images[current]} alt={`Image ${current + 1}`} />
                    <div className="arrow-left" onClick={previousImage}></div>
                    <div className="arrow-right" onClick={nextImage}></div>
                </div>
                <div className="banner__box">
                    <div className='banner__box__tittle'><h1>Bem Vindos a TransPC</h1></div>
                    <div className='banner__boxer'>
                        <div className='boxeach'>
                            <img src={truckGif} alt="truck-gif" />
                            <div className='box-text'>
                                <h1>Responsabilidade &</h1>
                                <h1>Compromisso</h1>
                            </div>
                        </div>
                        <div className='boxeachactive' onClick={handleOpenModal}>
                            <MdOutlineQuestionAnswer size={40} />
                            <div className='box-text'>
                                <h1>Solicitar Um Serviço</h1>
                            </div>
                        </div>
                        <div className='boxeach'>
                            <LiaBoxSolid size={50} style={{color: '#000'}}/>
                            <div className='box-text'>
                                <h1>Entregas de Mercadorias</h1>
                                <h1>Just In Time</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Modal closeModal={closeModal} />
            )}
        </>
    );
  };
  
  export default Banner;